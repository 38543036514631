import { useState } from "react"
import { Badge, Button, Form } from "react-bootstrap"
import { MdClose } from "react-icons/md"
import { WithContext as ReactTags } from "react-tag-input"

const KeyCodes = {
    comma: 188,
    enter: 13,
}
const delimiters = [KeyCodes.comma, KeyCodes.enter]

interface Props {
    tags: any,
    onChange: (args: any) => any
}

const InputTag = ({ tags, onChange }: Props) => {
    const [text, setText] = useState("")

    const addTag = () => {
        onChange([...tags, text.toUpperCase()]);
        setText("")
    }

    const deleteTag = (i:number) => {
        onChange(tags.filter((tag:any, index:number) => index !== i))
    }

    return(
        <div>
            <div className="d-flex flex-row gap-3">
                <Form.Control
                    type="text"
                    placeholder="Tambahkan Keahlian"
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                />
                <Button variant="brand" onClick={addTag}>Tambah</Button>
            </div>
            <div className="d-flex flex-row gap-2 flex-wrap mt-3">
                {
                    tags.map((item:any, index:number) => {
                        return(
                            <div key={"skill-"+index}>
                                <h4>
                                    <Badge bg="brand" className="d-flex flex-row gap-2">
                                        <div>{item}</div>
                                        <div className="pointer" onClick={() => deleteTag(index)}>
                                            <MdClose/>
                                        </div>
                                    </Badge>
                                </h4>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default InputTag