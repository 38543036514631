import { useEffect, useState } from "react"
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import ButtonLoading from "../components/ButtonLoading"
import InputTag from "../components/InputTag"
import imgPhotogrid from "../assets/img/photogrid-example.jpg"
import { PhotoProvider, PhotoView } from 'react-photo-view';
import ImageUploader from "../components/ImageUploader"
import SweetAlert from "../components/SweetAlert"
import DateRangePicker from "react-bootstrap-daterangepicker"
import moment from "moment"
import http from "../components/utils/Request"
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import FileUploader from "../components/FileUploader"
import { MdClose } from "react-icons/md"

const month = [
    { label: "Januari", value: "01" },
    { label: "Februari", value: "02" },
    { label: "Maret", value: "03" },
    { label: "April", value: "04" },
    { label: "Mei", value: "05" },
    { label: "Juni", value: "06" },
    { label: "Juli", value: "07" },
    { label: "Agustus", value: "08" },
    { label: "September", value: "09" },
    { label: "Oktober", value: "10" },
    { label: "November", value: "11" },
    { label: "Desember", value: "12" }
]

const TalentForm = () => {
    const navigate = useNavigate()
    const [form, setForm] = useState<any>({
        fullname: "",
        email: "",
        age: "",
        gender: "",
        birth_date: "",
        domicile: "",
        socmed_url : "",
        phone: "",
        position: [],
        height: "",
        weight: "",
        skill: [],
        description: "",
        resource: "",
        photo: "",
        agreement: false,
        agreement2: false,
        cv: "",
        education_level: "",
        education_major: "",
        education_institute: "",
        education_location: "",
        education_year_end: ""
    })
    const [job, setJob] = useState([
      {
        position: "",
        company_name: "",
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
        jobdesc: ""
      }
    ])
    const [loading, setLoading] = useState(false)
    const [city, setCity] = useState([])
    const [position, setPosition] = useState([])

    const yearRange = (startYear:number) => {
        var currentYear = new Date().getFullYear(), years = [];
        startYear = startYear || 1980;  
        while ( startYear <= currentYear ) {
            years.push(currentYear--);
        }   
        return years;
    }

    const loadCity = () =>  {
        http.get(`public/city`, {
            params: {
                order: [
                    {
                        field: 'ms_city_name',
                        sort: 'asc'
                    }
                ]
            }
        })
        .then((res) => {
            setCity(res.data.data.map((item:any) => ({ value: item.ms_city_id, label: item.ms_city_name })))
        })
    }

    const loadPosition = () =>  {
        http.get(`public/position_loker`, {
            params: {
                filter: [
                    {
                        field: "status",
                        operator: "=",
                        value: "1"
                    }
                ]
            }
        })
        .then((res) => {
            setPosition(res.data.data)
        })
    }

    const onSubmit = (e:any) => {
        e.preventDefault()

        var mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if(mailformat.test(form.email) === false){
            SweetAlert({
                icon: 'error',
                text: "Email tidak valid"
            })
            return false
        }

        if(!form.gender) {
            SweetAlert({
                icon: 'error',
                text: "Jenis Kelamin harus diisi"
            })

            return false
        }

        if(form.position.length === 0) {
            SweetAlert({
                icon: 'error',
                text: "Posisi pekerjaan minimal 1"
            })

            return false
        }

        if(form.skill.length === 0) {
            SweetAlert({
                icon: 'error',
                text: "Pengalaman minimal 1"
            })

            return false
        }

        if(!form.photo) {
            SweetAlert({
                icon: 'error',
                text: "Photogrid harus diisi"
            })

            return false
        }

        if(!form.cv) {
            SweetAlert({
                icon: 'error',
                text: "CV harus diisi"
            })

            return false
        }

        setLoading(true)

        http.post(`public/talent-register`, {
            name: form.fullname,
            email: form.email,
            phone: form.phone,
            resources: form.resource,
            age: form.age,
            height: form.height,
            weight: form.weight,
            skill: form.skill.toString(),
            domicile: form.domicile,
            birth_date: form.birth_date,
            socmed_url: form.socmed_url,
            position: form.position.toString(),
            description: form.description,
            photogrid: form.photo,
            gender: form.gender,
            cv: form.cv,
            level: form.education_level,
            major: form.education_major,
            institute: form.education_institute,
            location: form.education_location,
            year_end: form.education_year_end,
            jobs: job.map((x, i) => ({
                company_name: x.company_name,
                position: x.position,
                join_date: `${x.start_year}-${x.start_month}-01`,
                end_date: `${x.end_year}-${x.end_month}-01`,
                jobdesc: x.jobdesc,
            }))
        }).then((res) => {
            if (res.data.status) {
                navigate("/terima-kasih", { state: { key: "talent-form" } })
            } else {
                SweetAlert({
                    icon: 'error',
                    text: res.data.mesage
                })
            }
        }).catch((error) => {
            if (error.response) {
                SweetAlert({
                    icon: 'error',
                    text: error.response.data.error
                })
            } else {
                SweetAlert({
                    icon: 'error',
                    text: 'Oops.. Terjadi Kesalahan!'
                })
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const addJob = () => {
        setJob([...job, {
            position: "",
            company_name: "",
            start_month: "",
            start_year: "",
            end_month: "",
            end_year: "",
            jobdesc: ""
        }])
    }

    const deleteJob = (idx:number) => {
        setJob([...job].filter((x, i) => i!==idx))
    }

    useEffect(() => {
        loadCity()
        loadPosition()
    }, [])

    return(
        <div className="pt-5 pb-5">
            <Container>
                <Card>
                    <Card.Header className="bg-brand-opacity"><strong className="text-brand2">FORMULIR PENDAFTARAN</strong>
                        <div className="header mt-4">Pendaftaran Lowongan Pekerjaan TEMA</div>
                        <p className="mt-4">Form ini digunakan untuk melakukan pendataan kandidat yang berminat dan ingin bergabung dengan tim kami. Silahkan isi data  dibawah ini dengan lengkap dan benar.</p>
                    </Card.Header>
                    <Card.Body>
                        <PhotoProvider maskOpacity={.7}>
                            <Form onSubmit={onSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Nama Lengkap<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan Nama Lengkap"
                                                value={form.fullname}
                                                onChange={(event) => setForm((state:any) => ({...state, fullname: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Email<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan Email"
                                                value={form.email}
                                                onChange={(event) => setForm((state:any) => ({...state, email: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Jenis Kelamin<span className="text-danger"> *</span></strong></Form.Label>
                                            <div className="d-flex flex-row gap-4">
                                                <Form.Check
                                                    type={"radio"}
                                                    label={"Laki-laki"}
                                                    checked={form.gender === "male"}
                                                    onChange={(event) => setForm((state:any) => ({...state, gender: "male"}))}
                                                />
                                                <Form.Check
                                                    type={"radio"}
                                                    label={"Perempuan"}
                                                    checked={form.gender === "female"}
                                                    onChange={(event) => setForm((state:any) => ({...state, gender: "female"}))}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Tanggal Lahir<span className="text-danger"> *</span></strong></Form.Label>
                                            <DateRangePicker
                                                initialSettings={{
                                                    singleDatePicker: true,
                                                    autoUpdateInput: false,
                                                    showDropdowns: true,
                                                    locale: {
                                                        applyLabel: "Terapkan",
                                                        cancelLabel: "Batal"
                                                    }
                                                }}
                                                onCallback={(start) => setForm((state:any) => ({...state, birth_date: moment(start).format("YYYY-MM-DD")}))}
                                            >
                                                <Form.Control
                                                    placeholder="Tanggal Lahir"
                                                    defaultValue={form.birth_date}
                                                    disabled={loading}
                                                    required
                                                />
                                            </DateRangePicker>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Umur<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Masukan Umur"
                                                value={form.age}
                                                onChange={(event) => setForm((state:any) => ({...state, age: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Domisili<span className="text-danger"> *</span></strong></Form.Label>
                                            <Select
                                                placeholder="Pilih Domisili" 
                                                options={city} 
                                                onChange={(selected:any) => setForm((state:any) => ({...state, domicile: selected.label}))}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Username Instagram/Facebook<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan Username Instagram/Facebook"
                                                value={form.socmed_url}
                                                onChange={(event) => setForm((state:any) => ({...state, socmed_url: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">No. Handphone (WA)<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan No. Handphone (WA)"
                                                value={form.phone}
                                                onChange={(event) => setForm((state:any) => ({...state, phone: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Posisi Pekerjaan Apa Yang Kamu Cari?<span className="text-danger"> *</span></strong></Form.Label>
                                            {
                                                position.map((item:any, index:number) => (
                                                    <Form.Check
                                                        key={`position-${index}`}
                                                        type={"checkbox"}
                                                        label={item.position_loker_name}
                                                        onClick={() => {
                                                            const check = form.position.findIndex((x:any) => x === item.position_loker_name)
                                                            let positionTemp = [...form.position]
                                                            if(check >= 0){
                                                                setForm((state:any) => ({...state, position: positionTemp.splice(check, 1)}))
                                                            }else{
                                                                setForm((state:any) => ({...state, position: [...positionTemp, item.position_loker_name]}))
                                                            }
                                                        }}
                                                    />
                                                ))
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Tinggi Badan (cm)<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Masukan Tinggi Badan"
                                                value={form.height}
                                                onChange={(event) => setForm((state:any) => ({...state, height: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Berat Badan (kg)<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Masukan Berat Badan"
                                                value={form.weight}
                                                onChange={(event) => setForm((state:any) => ({...state, weight: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Darimana Kamu Mendapat Info Lowongan Ini?<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Masukan Data"
                                                value={form.resource}
                                                onChange={(event) => setForm((state:any) => ({...state, resource: event.target.value}))}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Keahlian<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Text muted></Form.Text>
                                            <InputTag
                                                tags={form.skill}
                                                onChange={(tags) => setForm((state:any) => ({ ...state, skill: tags}))}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Deskripsikan Diri Anda<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Text muted> Pengalaman, Hobi, Keahlian, dll</Form.Text>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                placeholder="Masukan Deskripsi"
                                                value={form.description}
                                                onChange={(event) => setForm((state:any) => ({...state, description: event.target.value}))}
                                                disabled={loading}
                                                rows={7}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>

                                    <hr/>
                                    <Col md={12} className="mb-3">
                                        <div className="title text-brand"><strong>Riwayat Pekerjaan</strong></div>
                                    </Col>
                                    <Col md={12}>
                                        
                                        {
                                            job.map((item, index) => {
                                                return(
                                                    <div key={"edu-"+index} className="d-flex flex-row gap-3 align-items-start mb-4">
                                                        <Row>
                                                            <Col md={6} className="mb-3">
                                                                <Form.Group>
                                                                    <Form.Label><strong className="text-black">Posisi<span className="text-danger"> *</span></strong></Form.Label>
                                                                    <Form.Control
                                                                        placeholder="Masukan Posisi"
                                                                        value={item.position}
                                                                        onChange={(event) => {
                                                                            const dataJob = job.map((row, idx) => idx === index ? 
                                                                            {...row, position: event.target.value} 
                                                                            : row)

                                                                            setJob(dataJob)
                                                                        }}
                                                                        disabled={loading}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6} className="mb-3">
                                                                <Form.Group>
                                                                    <Form.Label><strong className="text-black">Nama Perusahan<span className="text-danger"> *</span></strong></Form.Label>
                                                                    <Form.Control
                                                                        placeholder="Masukan Nama Perusahaan"
                                                                        value={item.company_name}
                                                                        onChange={(event) => {
                                                                            const dataJob = job.map((row, idx) => idx === index ? 
                                                                            {...row, company_name: event.target.value} 
                                                                            : row)

                                                                            setJob(dataJob)
                                                                        }}
                                                                        disabled={loading}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label><strong className="text-black">Mulai<span className="text-danger"> *</span></strong></Form.Label>
                                                                    <Row>
                                                                        <Col sm={6} md={5}>
                                                                            <Select
                                                                                placeholder="Bulan" 
                                                                                options={month} 
                                                                                onChange={(selected:any) => {
                                                                                    const dataJob = job.map((row, idx) => idx === index ? 
                                                                                    {...row, start_month: selected.value} 
                                                                                    : row)

                                                                                    setJob(dataJob)
                                                                                }}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={6} md={5}>
                                                                            <Select
                                                                                placeholder="Tahun" 
                                                                                options={yearRange(1980).map((x, y) => ({ label: x, value: x }))} 
                                                                                onChange={(selected:any) => {
                                                                                    const dataJob = job.map((row, idx) => idx === index ? 
                                                                                    {...row, start_year: selected.value} 
                                                                                    : row)

                                                                                    setJob(dataJob)
                                                                                }}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label><strong className="text-black">Berakhir<span className="text-danger"> *</span></strong></Form.Label>
                                                                    <Row>
                                                                        <Col sm={6} md={5}>
                                                                            <Select
                                                                                placeholder="Bulan" 
                                                                                options={month} 
                                                                                onChange={(selected:any) => {
                                                                                    const dataJob = job.map((row, idx) => idx === index ? 
                                                                                    {...row, end_month: selected.value} 
                                                                                    : row)

                                                                                    setJob(dataJob)
                                                                                }}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={6} md={5}>
                                                                            <Select
                                                                                placeholder="Tahun" 
                                                                                options={yearRange(1980).map((x, y) => ({ label: x, value: x }))} 
                                                                                onChange={(selected:any) => {
                                                                                    const dataJob = job.map((row, idx) => idx === index ? 
                                                                                    {...row, end_year: selected.value} 
                                                                                    : row)

                                                                                    setJob(dataJob)
                                                                                }}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label><strong className="text-black">Deskripsi</strong></Form.Label>
                                                                    <Form.Text muted> Deskripsikan tanggung jawab, keahlian, dan pencapaian kamu</Form.Text>
                                                                    <Form.Control
                                                                        type="text"
                                                                        as="textarea"
                                                                        placeholder="Masukan Deskripsi"
                                                                        value={item.jobdesc}
                                                                        onChange={(event) => {
                                                                            const dataJob = job.map((row, idx) => idx === index ? 
                                                                            {...row, jobdesc: event.target.value} 
                                                                            : row)

                                                                            setJob(dataJob)
                                                                        }}
                                                                        disabled={loading}
                                                                        rows={4}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Button variant="danger" disabled={loading} onClick={() => deleteJob(index)}><MdClose/></Button>
                                                        </Form.Group>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <Button variant="brand" disabled={loading} onClick={() => addJob()}>Tambah Pekerjaan</Button>
                                    </Col>
                                    
                                    <hr/>
                                    <Col md={12} className="mb-3">
                                        <div className="title text-brand"><strong>Pendidikan Terakhir</strong></div>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Jenjang Pendidikan<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                placeholder="Masukan Jenjang Pendidikan"
                                                value={form.level}
                                                onChange={(event) => setForm((state:any) => ({...state, education_level: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Jurusan</strong></Form.Label>
                                            <Form.Control
                                                placeholder="Masukan Jurusan"
                                                value={form.major}
                                                onChange={(event) => setForm((state:any) => ({...state, education_major: event.target.value}))}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Nama Intansi/Sekolah<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Control
                                                placeholder="Masukan Nama Intansi/Sekolah"
                                                value={form.intitute}
                                                onChange={(event) => setForm((state:any) => ({...state, education_institute: event.target.value}))}
                                                disabled={loading}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Lokasi Intansi/Sekolah</strong></Form.Label>
                                            <Form.Control
                                                placeholder="Masukan Lokasi Intansi/Sekolah"
                                                value={form.location}
                                                onChange={(event) => setForm((state:any) => ({...state, education_location: event.target.value}))}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Lulus Tahun</strong></Form.Label>
                                            <Select
                                                placeholder="Pilih Tahun" 
                                                options={yearRange(1980).map((x, y) => ({ label: x, value: x }))} 
                                                onChange={(selected:any) => setForm((state:any) => ({...state, education_year_end: selected.label}))}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <hr/>

                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">Photogrid (Nama/TB/BB)<span className="text-danger"> *</span></strong></Form.Label>
                                            <Form.Text muted> Contoh: (Nabila/163/55)</Form.Text>
                                            <br/>
                                            <Form.Text muted>Harap upload photo full body tanpa menggunakan hijab seperti contoh dibawah.</Form.Text>
                                            <div className="d-flex flex-row flex-wrap gap-3">
                                                <PhotoView src={imgPhotogrid}>
                                                    <img src={imgPhotogrid} width="200px" className="pointer" alt=""/>
                                                </PhotoView>
                                                <div style={{flex:1, height: "200px"}}>
                                                    <ImageUploader
                                                        value={form.photo}
                                                        onChange={(img) => setForm((state:any) => ({ ...state, photo: img }))}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label><strong className="text-black">CV<span className="text-danger"> *</span></strong></Form.Label>
                                            <FileUploader
                                                accept=".pdf,.doc,.docx,.txt,.pdfx"
                                                value={form.cv}
                                                onChange={(url) => setForm((state:any) => ({ ...state, cv: url }))}
                                                desc="(Type of file: pdf, doc, docx, txt, pdfx)"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <div className="d-flex flex-row gap-2">
                                            <Form.Check
                                                type={"checkbox"}
                                                checked={form.agreement}
                                                onChange={() => setForm((state:any) => ({...state, agreement: !form.agreement}))}
                                                required
                                            />
                                            <p className="flex-fill">
                                                Dengan ini saya menyetuji <a href="/ketentuan-layanan" target="_blank" className="text-brand">Ketentuan Layanan</a> & <a href="/kebijakan-privasi" target="_blank" className="text-brand">Kebijakan Privasi</a> yang berlaku.
                                            </p>
                                        </div>
                                        <div className="d-flex flex-row gap-2" style={{marginTop:-10}}>
                                            <Form.Check
                                                type={"checkbox"}
                                                checked={form.agreement2}
                                                onChange={() => setForm((state:any) => ({...state, agreement2: !form.agreement2}))}
                                                required
                                            />
                                            <p className="flex-fill">
                                                Saya bersedia tanpa menggunakan hijab pada saat bekerja.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={12} className="mt-4">
                                        <ButtonLoading type="submit" variant="brand" disabled={!form.agreement || !form.agreement2} loading={loading.toString()}><strong>Kirim</strong></ButtonLoading>
                                    </Col>
                                </Row>
                            </Form>
                        </PhotoProvider>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default TalentForm